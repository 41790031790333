export default {
    address: "台北市南港區經貿一路75巷",
    googleSrc: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7228.664464831197!2d121.61567463493355!3d25.05672621388963!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3c111ebbf4112b20!2zMjXCsDAzJzI0LjIiTiAxMjHCsDM3JzEyLjIiRQ!5e0!3m2!1szh-TW!2stw!4v1646731202847!5m2!1szh-TW!2stw",
    googleLink: "https://goo.gl/maps/h419ePWFHUeYofBS7",
    phone: "02-26538888",
    fbLink: "https://www.facebook.com/DaxinForest/",
    fbMessage: "https://m.me/DaxinForest/",
    caseName: "達欣東匯",
    indigatorLength: 10,
    houseInfos: [
        ["投資興建", "達欣開發股份有限公司"],
        ["建築規劃", "林秀芬建築師事務所"],
        ["景觀設計", "太研規劃設計顧問"],
        ["風格美學", "樂工設計"],
        ["結構工程", "築遠工程顧問有限公司"],
        ["綠建築顧問", "澄毓設計顧問有限公司"],
        ["建築代銷", "聯碩行銷股份有限公司"],
        ["不動產經紀人", "李濟強100年北市經證字01732號"],
        ["建照號碼", "111建字第0120號"],
        ["規劃格局", "2-3房"],
        // ["行銷企劃", "<img src=" + require("@/projects/cfyd/other.svg") + ">"]
    ],

    gtmCode: ["N8PS4XM"], // 可放置多個
    recaptcha_site_key_v2: "6LfGUjEaAAAAANYvhZQx5imGm23pRt0V-rOvkpNC",
    recaptcha_site_key: "6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s", // recaptcha v3
    recaptcha_user_token: "6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa",
    order: {
        title: "預約賞屋",
        subTitle: ""
    }
};