<template>
  <div class="s2">
    <div class="item" data-aos="fade"></div>
    <div class="info">
      <div class="t1" data-aos="fade-up">
        東區門戶，
        <span class="large">3</span>
        倍信義計劃
      </div>
      <div class="t2" data-aos="fade-up" data-aos-delay="100" v-if="!isMobile">
        總面積440公頃，比信義計劃區大3倍，<br />政府領軍，挹資近6千億。<br />
        五大中心、八大構想，發展趕超信義計劃。
      </div>
      <div class="t2" data-aos="fade-up" data-aos-delay="100" v-else>
        總面積440公頃，比信義計劃區大3倍，政府領軍，挹資近6千億。
        五大中心、八大構想，發展趕超信義計劃。
      </div>
      <div class="circle" v-if="!isMobile">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="442.736"
          height="427.9"
          viewBox="0 0 442.736 427.9"
        >
          <g transform="translate(4380.507 -1561.334)">
            <g data-aos="fade" data-aos-delay="0" data-aos-offset="1700">
              <circle
                cx="70.183"
                cy="70.183"
                r="70.183"
                transform="translate(-4259 1658.466) rotate(-45)"
                fill="#fff"
                opacity="0.45"
              />
              <text
                transform="translate(-4214 1647.416)"
                fill="#1b485b"
                font-size="18.152"
                font-family="NotoSerifCJKtc-Bold, Noto Serif CJK TC"
                font-weight="700"
              >
                <tspan x="0" y="0">生技產業中心</tspan>
              </text>
              <text
                transform="translate(-4157 1669.518)"
                fill="#1b485b"
                font-size="13.6"
                font-family="Noto Serif CJK TC"
                font-weight="600"
              >
                <tspan x="-44.555" y="0">台北生技園區</tspan>
                <tspan x="-35.161" y="21.452">500億產值</tspan>
              </text>
            </g>
            <g data-aos="fade" data-aos-delay="100" data-aos-offset="1700">
              <circle
                cx="70.183"
                cy="70.183"
                r="70.183"
                transform="translate(-4259.125 1658.466) rotate(-45)"
                fill="none"
                stroke="#fff"
                stroke-miterlimit="10"
                stroke-width="5"
              />
              <circle
                cx="70.183"
                cy="70.183"
                r="70.183"
                transform="translate(-4128 1756.437) rotate(-45)"
                fill="#fff"
                opacity="0.45"
              />
              <text
                transform="translate(-4027 1767.488)"
                fill="#1b485b"
                font-size="13.6"
                font-family="Noto Serif CJK TC"
                font-weight="600"
              >
                <tspan x="-55.98" y="0">南港展覽館，全台</tspan>
                <tspan x="-46.003" y="21.452">最大5000展攤</tspan>
                <tspan x="0" y="42.904"></tspan>
              </text>
              <text
                transform="translate(-4069 1745.386)"
                fill="#1b485b"
                font-size="18.152"
                font-family="NotoSerifCJKtc-Bold, Noto Serif CJK TC"
                font-weight="700"
              >
                <tspan x="-15" y="0">國家會展中心</tspan>
              </text>
            </g>
            <g data-aos="fade" data-aos-delay="200" data-aos-offset="1700">
              <circle
                cx="70.183"
                cy="70.183"
                r="70.183"
                transform="translate(-4128.5 1756.437) rotate(-45)"
                fill="none"
                stroke="#fff"
                stroke-miterlimit="10"
                stroke-width="5"
              />
              <circle
                cx="70.183"
                cy="70.183"
                r="70.183"
                transform="translate(-4160.5 1955.815) rotate(-76.717)"
                fill="none"
                stroke="#fff"
                stroke-miterlimit="10"
                stroke-width="5"
              />
              <circle
                cx="70.183"
                cy="70.183"
                r="70.183"
                transform="translate(-4161 1955.815) rotate(-76.717)"
                fill="#fff"
                opacity="0.45"
              />
              <text
                transform="translate(-4131 1895.558)"
                fill="#1b485b"
                font-size="18.152"
                font-family="NotoSerifCJKtc-Bold, Noto Serif CJK TC"
                font-weight="700"
              >
                <tspan x="0" y="0">文創產業中心</tspan>
              </text>
              <text
                transform="translate(-4071 1917.661)"
                fill="#1b485b"
                font-size="13.6"
                font-family="Noto Serif CJK TC"
                font-weight="600"
              >
                <tspan x="-30.238" y="0">8.92公頃</tspan>
                <tspan x="-59.406" y="21.452">台北流行音樂中心</tspan>
              </text>
            </g>
            <g data-aos="fade" data-aos-delay="300" data-aos-offset="1700">
              <circle
                cx="70.183"
                cy="70.183"
                r="70.183"
                transform="matrix(0.23, -0.973, 0.973, 0.23, -4378, 1805.643)"
                fill="#fff"
                opacity="0.45"
              />
              <text
                transform="translate(-4348 1745.386)"
                fill="#1b485b"
                font-size="18.152"
                font-family="NotoSerifCJKtc-Bold, Noto Serif CJK TC"
                font-weight="700"
              >
                <tspan x="0" y="0">交通轉運中心</tspan>
              </text>
              <text
                transform="translate(-4292 1767.488)"
                fill="#1b485b"
                font-size="13.6"
                font-family="Noto Serif CJK TC"
                font-weight="600"
              >
                <tspan x="-28.852" y="0">五鐵共構</tspan>
                <tspan x="-37.129" y="21.452">南港大車站</tspan>
              </text>
            </g>
            <g data-aos="fade" data-aos-delay="400" data-aos-offset="1700">
              <circle
                cx="70.183"
                cy="70.183"
                r="70.183"
                transform="matrix(0.23, -0.973, 0.973, 0.23, -4377.5, 1805.643)"
                fill="none"
                stroke="#fff"
                stroke-miterlimit="10"
                stroke-width="5"
              />
              <circle
                cx="70.183"
                cy="70.183"
                r="70.183"
                transform="translate(-4329 1955.815) rotate(-76.717)"
                fill="#fff"
                opacity="0.45"
              />
              <circle
                cx="70.183"
                cy="70.183"
                r="70.183"
                transform="translate(-4329.5 1955.815) rotate(-76.717)"
                fill="none"
                stroke="#fff"
                stroke-miterlimit="10"
                stroke-width="5"
              />
              <text
                transform="translate(-4300 1895.558)"
                fill="#1b485b"
                font-size="18.152"
                font-family="NotoSerifCJKtc-Bold, Noto Serif CJK TC"
                font-weight="700"
              >
                <tspan x="0" y="0">軟體產業中心</tspan>
              </text>
              <text
                transform="translate(-4241 1917.661)"
                fill="#1b485b"
                font-size="13.6"
                font-family="Noto Serif CJK TC"
                font-weight="600"
              >
                <tspan x="-44.555" y="0">南港軟體園區</tspan>
                <tspan x="-59.406" y="21.452">千億產值亞洲第一</tspan>
              </text>
            </g>
          </g>
        </svg>
      </div>
      <div class="circle" v-else>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="442.736"
          height="427.9"
          viewBox="0 0 442.736 427.9"
        >
          <g transform="translate(4380.507 -1561.334)">
            <g data-aos="fade" data-aos-delay="0" data-aos-offset="1300">
              <circle
                cx="70.183"
                cy="70.183"
                r="70.183"
                transform="translate(-4259 1658.466) rotate(-45)"
                fill="#fff"
                opacity="0.45"
              />
              <text
                transform="translate(-4214 1647.416)"
                fill="#1b485b"
                font-size="18.152"
                font-family="NotoSerifCJKtc-Bold, Noto Serif CJK TC"
                font-weight="700"
              >
                <tspan x="0" y="0">生技產業中心</tspan>
              </text>
              <text
                transform="translate(-4157 1669.518)"
                fill="#1b485b"
                font-size="13.6"
                font-family="Noto Serif CJK TC"
                font-weight="600"
              >
                <tspan x="-44.555" y="0">台北生技園區</tspan>
                <tspan x="-35.161" y="21.452">500億產值</tspan>
              </text>
            </g>
            <g data-aos="fade" data-aos-delay="100" data-aos-offset="1300">
              <circle
                cx="70.183"
                cy="70.183"
                r="70.183"
                transform="translate(-4259.125 1658.466) rotate(-45)"
                fill="none"
                stroke="#fff"
                stroke-miterlimit="10"
                stroke-width="5"
              />
              <circle
                cx="70.183"
                cy="70.183"
                r="70.183"
                transform="translate(-4128 1756.437) rotate(-45)"
                fill="#fff"
                opacity="0.45"
              />
              <text
                transform="translate(-4027 1767.488)"
                fill="#1b485b"
                font-size="13.6"
                font-family="Noto Serif CJK TC"
                font-weight="600"
              >
                <tspan x="-55.98" y="0">南港展覽館，全台</tspan>
                <tspan x="-46.003" y="21.452">最大5000展攤</tspan>
                <tspan x="0" y="42.904"></tspan>
              </text>
              <text
                transform="translate(-4069 1745.386)"
                fill="#1b485b"
                font-size="18.152"
                font-family="NotoSerifCJKtc-Bold, Noto Serif CJK TC"
                font-weight="700"
              >
                <tspan x="-15" y="0">國家會展中心</tspan>
              </text>
            </g>
            <g data-aos="fade" data-aos-delay="200" data-aos-offset="1300">
              <circle
                cx="70.183"
                cy="70.183"
                r="70.183"
                transform="translate(-4128.5 1756.437) rotate(-45)"
                fill="none"
                stroke="#fff"
                stroke-miterlimit="10"
                stroke-width="5"
              />
              <circle
                cx="70.183"
                cy="70.183"
                r="70.183"
                transform="translate(-4160.5 1955.815) rotate(-76.717)"
                fill="none"
                stroke="#fff"
                stroke-miterlimit="10"
                stroke-width="5"
              />
              <circle
                cx="70.183"
                cy="70.183"
                r="70.183"
                transform="translate(-4161 1955.815) rotate(-76.717)"
                fill="#fff"
                opacity="0.45"
              />
              <text
                transform="translate(-4131 1895.558)"
                fill="#1b485b"
                font-size="18.152"
                font-family="NotoSerifCJKtc-Bold, Noto Serif CJK TC"
                font-weight="700"
              >
                <tspan x="0" y="0">文創產業中心</tspan>
              </text>
              <text
                transform="translate(-4071 1917.661)"
                fill="#1b485b"
                font-size="13.6"
                font-family="Noto Serif CJK TC"
                font-weight="600"
              >
                <tspan x="-30.238" y="0">8.92公頃</tspan>
                <tspan x="-59.406" y="21.452">台北流行音樂中心</tspan>
              </text>
            </g>
            <g data-aos="fade" data-aos-delay="300" data-aos-offset="1300">
              <circle
                cx="70.183"
                cy="70.183"
                r="70.183"
                transform="matrix(0.23, -0.973, 0.973, 0.23, -4378, 1805.643)"
                fill="#fff"
                opacity="0.45"
              />
              <text
                transform="translate(-4348 1745.386)"
                fill="#1b485b"
                font-size="18.152"
                font-family="NotoSerifCJKtc-Bold, Noto Serif CJK TC"
                font-weight="700"
              >
                <tspan x="0" y="0">交通轉運中心</tspan>
              </text>
              <text
                transform="translate(-4292 1767.488)"
                fill="#1b485b"
                font-size="13.6"
                font-family="Noto Serif CJK TC"
                font-weight="600"
              >
                <tspan x="-28.852" y="0">五鐵共構</tspan>
                <tspan x="-37.129" y="21.452">南港大車站</tspan>
              </text>
            </g>
            <g data-aos="fade" data-aos-delay="400" data-aos-offset="1300">
              <circle
                cx="70.183"
                cy="70.183"
                r="70.183"
                transform="matrix(0.23, -0.973, 0.973, 0.23, -4377.5, 1805.643)"
                fill="none"
                stroke="#fff"
                stroke-miterlimit="10"
                stroke-width="5"
              />
              <circle
                cx="70.183"
                cy="70.183"
                r="70.183"
                transform="translate(-4329 1955.815) rotate(-76.717)"
                fill="#fff"
                opacity="0.45"
              />
              <circle
                cx="70.183"
                cy="70.183"
                r="70.183"
                transform="translate(-4329.5 1955.815) rotate(-76.717)"
                fill="none"
                stroke="#fff"
                stroke-miterlimit="10"
                stroke-width="5"
              />
              <text
                transform="translate(-4300 1895.558)"
                fill="#1b485b"
                font-size="18.152"
                font-family="NotoSerifCJKtc-Bold, Noto Serif CJK TC"
                font-weight="700"
              >
                <tspan x="0" y="0">軟體產業中心</tspan>
              </text>
              <text
                transform="translate(-4241 1917.661)"
                fill="#1b485b"
                font-size="13.6"
                font-family="Noto Serif CJK TC"
                font-weight="600"
              >
                <tspan x="-44.555" y="0">南港軟體園區</tspan>
                <tspan x="-59.406" y="21.452">千億產值亞洲第一</tspan>
              </text>
            </g>
          </g>
        </svg>
      </div>
    </div>
    <div class="divider" data-aos="scaleY"></div>
  </div>
</template>
<style lang="scss" scoped>
@import "@/assets/style/function.scss";
/* 螢幕尺寸標準 */
.s2 {
  position: relative;
  width: size(1920);
  height: size(1080);
  background-image: url("~@/projects/dh/bg.jpg");
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .item {
    width: size(1100);
    height: size(800);
    background-image: url("~@/projects/dh/s2/a.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    border: size(2) solid #1b485b;
  }

  .info {
    color: #1b485b;
    text-align: left;
    padding-right: size(120);
    .t1 {
      font-size: size(55);
      font-weight: bold;
      line-height: 1.5;
      .large {
        display: inline;
        font-size: size(69);
        margin-right: -12px;
      }
    }
    .t2 {
      margin-top: size(35);
      font-size: size(26);
      font-weight: 500;
      line-height: 2;
    }
    .circle {
      margin-top: size(30);
      width: size(410);
      svg {
        width: 100%;
        height: 100%;
        transform: size(5);
      }
    }
  }

  .divider {
    position: absolute;
    width: size(2);
    height: size(160);
    background: #c28c65;
    bottom: -#{size(100)};
    left: 50%;
    z-index: 10;
  }
}
/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

/* 手機尺寸 */

@media only screen and (max-width: 767px) {
  .s2 {
    position: relative;
    width: size-m(375);
    height: size-m(1127);
    background-image: url("~@/projects/dh/bg_m.jpg");
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .item {
      width: 100%;
      height: 100%;
      background-image: url("~@/projects/dh/s2/a_m.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      border: 0;
    }

    .info {
      position: absolute;
      color: #fff;
      text-align: left;
      padding-right: 0;
      top: size-m(10);
      left: size-m(20);
      .t1 {
        font-size: size-m(26);
        line-height: 1;
        .large {
          font-size: size-m(30);
          margin-right: 0;
        }
      }
      .t2 {
        margin-top: size-m(10);
        font-size: size-m(13);
        font-weight: 500;
        line-height: 1.5;
        width: size-m(230);
      }
      .circle {
        position: absolute;
        top: size-m(550);
        left: 50%;
        margin-top: 0;
        width: size-m(280);
        transform: translateX(-50%);
        margin-left: size-m(20);
        svg {
          width: 100%;
          height: 100%;
          transform: size(5);
        }
      }
    }

    .divider {
      display: none;
    }
  }
}

// 避免內容電腦過渡平板時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1199.98px) {
}

// 避免過度到 1280 x 720 時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1280px) {
}
</style>

<script>
// @ is an alias to /src
import { isMobile } from "@/utils";

export default {
  name: "s2",
  props: ["scrollInstance"],
  data() {
    return {
      isMobile,
    };
  },
};
</script>